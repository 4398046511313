import request from '@/utils/request'
import { get } from 'dot-prop'
import moment from 'moment'

// Constants
import {
  API_GET_PROFILE,
} from '@/constants/apis'

import {
  SET_PROFILE,
  INIT_FORM_RECOMMEND,
  ADD_FORM_RECOM,
  REMOVE_FORM_RECOM,
  SET_ERROR_RECOMMENDATION,
  SET_IS_LOGIN_INFO
} from '../mutation-type'

import {
  student_data,
  academic_and_general,
  character_evaluation,
  character_evaluation_sert,
  character_evaluation_c_mth,
  recommendation,
  recommendation_sert,
  recommendation_other_person,
  filler_data,
  agreement_data,
  academic_and_general_sert,
  academic_and_general_mmin,
  academic_and_general_mth,
  academic_and_general_c_mth
} from '@/constants/formRecommendContent'

const state = {
  profile: {
    name: '',
    email: '',
    phone: '',
    image: ''
  },
  formRecommend: {},
  errorRecommend: false,
  isLoginInfo: false
}

const mutations = {
  [SET_PROFILE] (_state, data) {
    _state.profile = {
      name: get(data, 'name', ''),
      email: get(data, 'email', ''),
      phone: get(data, 'phone', ''),
      image: get(data, 'image', '')
    }
  },
  [INIT_FORM_RECOMMEND] (_state, data) {
    _state.formRecommend = data
  },
  [ADD_FORM_RECOM] (_state, data) {
    let len = _state.formRecommend[data.parent].length + 1
    _state.formRecommend[data.parent].map(itm => {
      if (itm.group === data.group) {
        const item = {...itm}
        item.order = len
        item.title = itm.title.slice(0, -1) + (itm.num+1)
        item.attr = itm.attr.slice(0, -1) + (itm.num+1)
        item.group = itm.group + 1
        item.num = itm.num + 1
        item.value = ''
        item.isDisabledRemove = false
        if (item.type === 'select') {
          item.value = item.option[0]
        }
        itm.isMultiData = false
        _state.formRecommend[data.parent].push(item)
        len++
      }
    })
  },
  [REMOVE_FORM_RECOM] (_state, data) {
    const filtered = []
    _state.formRecommend[data.parent].map(itm => {
      if (itm.group !== data.group) {
        filtered.push(itm)
      }
      if (itm.group === data.group-1 && itm.isMultiData === false) {
        itm.isMultiData = true
      }
    })

    _state.formRecommend[data.parent] = filtered
  },
  [SET_ERROR_RECOMMENDATION] (_state, data) {
    _state.errorRecommend = data
  },
  [SET_IS_LOGIN_INFO] (_state, data) {
    _state.isLoginInfo = data
  },
}

const actions = {
  getProfile ({ commit }) {
    return new Promise((_resolve, _reject) => {
      request(API_GET_PROFILE, {}, {methods: 'GET'}).then(res => {
        if (res.success) {
          commit(SET_PROFILE, res)
          return _resolve({success: true})
        } else return _reject(res)
      })
    })
  },
  getFormRecommend ({ commit }, params) {
    const forms = {
      student_data: JSON.parse(JSON.stringify(student_data)),
      academic_and_general: JSON.parse(JSON.stringify(academic_and_general)),
      character_evaluation: JSON.parse(JSON.stringify(character_evaluation)),
      recommendation: JSON.parse(JSON.stringify(recommendation)),
      recommendation_other_person: JSON.parse(JSON.stringify(recommendation_other_person)),
      filler_data: JSON.parse(JSON.stringify(filler_data)),
      agreement_data: JSON.parse(JSON.stringify(agreement_data)),
    }
    if (params.type === 'C' && params.prodi === 'mth') {
      forms.academic_and_general = JSON.parse(JSON.stringify(academic_and_general_c_mth))
    } else if (['mmin'].indexOf(params.prodi) !== -1) {
      forms.academic_and_general = JSON.parse(JSON.stringify(academic_and_general_mmin))
    } else if (['sert'].indexOf(params.prodi) !== -1) {
      forms.academic_and_general = JSON.parse(JSON.stringify(academic_and_general_sert))
      forms.character_evaluation = JSON.parse(JSON.stringify(character_evaluation_sert))
      forms.recommendation = JSON.parse(JSON.stringify(recommendation_sert))
    } else if (['mth'].indexOf(params.prodi) !== -1) {
      forms.academic_and_general = JSON.parse(JSON.stringify(academic_and_general_mth))
    }
    commit(INIT_FORM_RECOMMEND, {...forms})
  },
  addFormRecom ({ commit }, data) {
    commit(ADD_FORM_RECOM, data) 
  },
  removeFormRecom ({ commit }, data) {
    commit(REMOVE_FORM_RECOM, data) 
  },
  setErrorRecommendation ({commit}, data) {
    commit(SET_ERROR_RECOMMENDATION, data)
  },
  setIsLoginInfo ({commit}, data) {
    commit(SET_IS_LOGIN_INFO, data)
  }
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
