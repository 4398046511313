export const student_data = [{
  id: 'question1',
  parent: 'student_data',
  title: 'Nama Calon Mahasiswa',
  fields: 'question_1',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 1 
}]

// STH/MTHU
export const academic_and_general = [{
  id: 'question2',
  parent: 'academic_and_general',
  title: 'Berapa lama, seberapa baik dan dalam kapasitas sebagai apakah Anda mengenal calon mahasiswa?',
  fields: 'question_2',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 1,
  group: 1,
}, {
  id: 'question5',
  parent: 'academic_and_general',
  title: 'Seberapa besar motivasi calon mahasiswa untuk menjalani pendidikan teologi?',
  fields: 'question_5',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 4,
  group: 2,
}, {
  id: 'question6',
  parent: 'academic_and_general',
  title: 'Apakah sebagai anggota gereja, calon mahasiswa selama ini ikut terlibat dalam pelayanan?',
  fields: 'question_6',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 5,
  group: 2,
}]
export const academic_and_general_sert = [{
  id: 'question2',
  parent: 'academic_and_general',
  title: 'Berapa lama, seberapa baik dan dalam kapasitas sebagai apakah Anda mengenal calon mahasiswa?',
  fields: 'question_2',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 1,
  group: 1,
}, {
  id: 'question1_a',
  parent: 'academic_and_general',
  title: 'Pemahaman Kreativitas',
  head: 'Kualitas Intelektual',
  fields: '1_a',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Sangat Baik', 'Baik','Rata-rata', 'Kurang'],
  order: 2,
  group: 2,
}, {
  id: 'question1_b',
  parent: 'academic_and_general',
  title: 'Ketertarikan terhadap Penelitian',
  head: 'Kualitas Intelektual',
  fields: '1_b',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Sangat Baik', 'Baik','Rata-rata', 'Kurang'],
  order: 3,
  group: 2,
}, {
  id: 'question1_c',
  parent: 'academic_and_general',
  title: 'Kemampuan Berefleksi',
  head: 'Kualitas Intelektual',
  fields: '1_c',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Sangat Baik', 'Baik','Rata-rata', 'Kurang'],
  order: 4,
  group: 2,
}, {
  id: 'question1_d',
  parent: 'academic_and_general',
  title: 'Kemandirian',
  fields: '1_d',
  head: 'Kualitas Intelektual',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Sangat Baik', 'Baik','Rata-rata', 'Kurang'],
  order: 5,
  group: 2,
}, {
  id: 'question2_a',
  parent: 'academic_and_general',
  title: 'Secara Verbal',
  head: 'Kelancaran Berbahasa Inggris',
  fields: '2_a',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Sangat Baik', 'Baik','Rata-rata', 'Kurang'],
  order: 6,
  group: 3,
}, {
  id: 'question2_b',
  parent: 'academic_and_general',
  title: 'Secara Tertulis',
  head: 'Kelancaran Berbahasa Inggris',
  fields: '2_b',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Sangat Baik', 'Baik','Rata-rata', 'Kurang'],
  order: 7,
  group: 3,
}, {
  id: 'question3',
  parent: 'academic_and_general',
  title: 'Apakah calon mahasiswa secara nyata hidup dalam pertobatannya sebagai seorang Kristen?',
  fields: 'question_3',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 8,
  group: 4,
}, {
  id: 'question6',
  parent: 'academic_and_general',
  title: 'Apakah sebagai anggota gereja, calon mahasiswa selama ini ikut terlibat dalam pelayanan?',
  fields: 'question_6',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 11,
  group: 4,
}]
export const academic_and_general_mth = [{
  id: 'question2',
  parent: 'academic_and_general',
  title: 'Berapa lama, seberapa baik dan dalam kapasitas sebagai apakah Anda mengenal calon mahasiswa?',
  fields: 'question_2',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 1,
  group: 1,
}, {
  id: 'question4',
  parent: 'academic_and_general',
  title: 'Bagaimana pendapat Anda tentang semangat/kesungguhannya dalam pelayanan sebagai rohaniwan?',
  fields: 'question_4',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 2,
  group: 2,
}, {
  id: 'question5',
  parent: 'academic_and_general',
  title: 'Beberapa besar motivasi calon mahasiswa untuk menjalani pendidikan teologi?',
  fields: 'question_5',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 3,
  group: 2,
}, {
  id: 'question6',
  parent: 'academic_and_general',
  title: 'Apakah sebagai calon mahasiswa menunjukkan kesetiaan/kegigihan dalam pelayanan sebagai rohaniwan?',
  fields: 'question_6',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 4,
  group: 2,
}]
export const academic_and_general_mmin = [{
  id: 'question2',
  parent: 'academic_and_general',
  title: 'Berapa lama, seberapa baik dan dalam kapasitas sebagai apakah Anda mengenal calon mahasiswa?',
  fields: 'question_2',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 1,
  group: 1,
}, {
  id: 'question3',
  parent: 'academic_and_general',
  title: 'Apakah calon mahasiswa secara nyata hidup dalam pertobatannya sebagai seorang Kristen?',
  fields: 'question_3',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 2,
  group: 2,
}, {
  id: 'question4',
  parent: 'academic_and_general',
  title: 'Apakah sebagai anggota gereja, calon mahasiswa selama ini terlibat dalam pelayanan?',
  fields: 'question_4',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 3,
  group: 2,
}]
export const academic_and_general_c_mth = [{
  id: 'question2',
  parent: 'academic_and_general',
  title: 'Berapa lama, seberapa baik dan dalam kapasitas sebagai apakah Anda mengenal calon mahasiswa?',
  fields: 'question_2',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 1,
  group: 1,
}, {
  id: 'question3',
  parent: 'academic_and_general',
  title: 'Apakah hal positif dari calon mahasiswa yang dapat mendukung dalam menjalani studi Magister?',
  fields: 'question_3',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 8,
  group: 2,
}, {
  id: 'question4',
  parent: 'academic_and_general',
  title: 'Adakah kekurangan yang perlu diperhatikan dari calon mahasiswa?',
  fields: 'question_4',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 9,
  group: 2,
}]

export const character_evaluation = [{
  id: 'a_radio_3',
  parent: 'character_evaluation',
  title: 'Memiliki Kestabilan Emosi (bagaimana calon mahasiswa bereaksi dalam keadaan stres)',
  fields: 'a_radio_3',
  value: 'Sangat Tidak Setuju',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Sangat Tidak Setuju', 'Tidak Setuju', 'Setuju','Sangat Setuju'],
  order: 3,
  group: 1,
}, {
  id: 'a_radio_5',
  parent: 'character_evaluation',
  title: 'Memiliki hubungan intim dengan Orang tua',
  fields: 'a_radio_5',
  value: 'Sangat Tidak Setuju',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Sangat Tidak Setuju', 'Tidak Setuju', 'Setuju','Sangat Setuju'],
  order: 5,
  group: 1,
}, {
  id: 'a_radio_6',
  parent: 'character_evaluation',
  title: 'Memiliki hubungan yang baik dengan Suami/Istri (Opsional, jika sudah menikah)',
  fields: 'a_radio_6',
  value: 'Sangat Tidak Setuju',
  validation: {
    required: false
  },
  type: 'select',
  option: ['Sangat Tidak Setuju', 'Tidak Setuju', 'Setuju','Sangat Setuju'],
  order: 6,
  group: 1,
}, {
  id: 'a_radio_8',
  parent: 'character_evaluation',
  title: 'Memiliki Tanggung Jawab',
  fields: 'a_radio_8',
  value: 'Sangat Tidak Setuju',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Sangat Tidak Setuju', 'Tidak Setuju', 'Setuju','Sangat Setuju'],
  order: 8,
  group: 1,
}, {
  id: 'a_radio_12',
  parent: 'character_evaluation',
  title: 'Memiliki Jiwa Kepemimpinan',
  fields: 'a_radio_12',
  value: 'Sangat Tidak Setuju',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Sangat Tidak Setuju', 'Tidak Setuju', 'Setuju','Sangat Setuju'],
  order: 12,
  group: 1,
}, {
  id: 'a_radio_13',
  parent: 'character_evaluation',
  title: 'Memiliki Hati yang Mau Diajar',
  fields: 'a_radio_13',
  value: 'Sangat Tidak Setuju',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Sangat Tidak Setuju', 'Tidak Setuju', 'Setuju','Sangat Setuju'],
  order: 13,
  group: 1,
}, {
  id: 'b_checkbox',
  parent: 'character_evaluation',
  title: 'Berikut ini adalah beberapa kecenderungan yang jika timbul dapat mengurangi efektifitas calon mahasiswa dalam bekerja dan bersaksi. Mohon berikan tanda yang menurut Anda menggambarkan salah satu atau beberapa kecenderungan calon mahasiswa.',
  fields: 'b_checkbox',
  value: [],
  validation: {
    required: false
  },
  option: [
    'Tidak sabar, tidak bertoleransi, argumentatif, mendominasi, merengut, sombong, suka mengkritik.',
    'Mudah merasa malu, mudah tersinggung, kecil hati, depresi, mudah kesal.',
    'Sering kuatir, gelisah, grogi atau tegang.',
    'Membeda-bedakan kelompok tertentu, ras atau kebangsaan.',
    'Berteman secara eksklusif.',
  ],
  type: 'checkbox',
  order: 14,
  group: 2,
}, {
  id: 'b_question_1',
  parent: 'character_evaluation',
  title: 'Jelaskan bentuk dan intensitas kecenderungan yang Anda garis bawahi di atas',
  fields: 'b_question_1',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 15,
  group: 2,
}, {
  id: 'is_free_from_tendencies',
  parent: 'character_evaluation',
  title: 'Calon mahasiswa terlihat bebas dari kecenderungan tersebut di atas',
  fields: 'is_free_from_tendencies',
  value: 'Ya',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Ya', 'Tidak'],
  order: 16,
  group: 2,
}, {
  id: 'c_question_1',
  parent: 'character_evaluation',
  title: 'Apakah calon mahasiswa pernah mengalami suatu penyakit atau ketegangan mental? Jika ya, Bagaimana ia mengatasinya?',
  fields: 'c_question_1',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 17,
  group: 3,
}]
export const character_evaluation_sert = [{
  id: 'a_radio_1',
  parent: 'character_evaluation',
  title: 'Penampilan Pribadi dan Kelakuan',
  fields: 'a_radio_1',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Ceroboh', 'Cukup Baik','Rapi', 'Baik'],
  order: 1,
  group: 1,
}, {
  id: 'a_radio_2',
  parent: 'character_evaluation',
  title: 'Sikap',
  fields: 'a_radio_2',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Tidak Percaya Diri', 'Kurang Percaya Diri','Cukup Percaya Diri', 'Percaya Diri'],
  order: 2,
  group: 1,
}, {
  id: 'a_radio_3',
  parent: 'character_evaluation',
  title: 'Memiliki Kestabilan Emosi (bagaimana calon mahasiswa bereaksi dalam keadaan stres)',
  fields: 'a_radio_3',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Tidak Stabil', 'Cukup Seimbang','Seimbang', 'Kadang Tidak Seimbang'],
  order: 3,
  group: 1,
}, {
  id: 'a_radio_4',
  parent: 'character_evaluation',
  title: 'Penerimaan Sosial (bagaimana kepribadian calon mahasiswa berdampak kepada orang lain)',
  fields: 'a_radio_4',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Dapat Ditoleransi', 'Diterima','Disukai', 'Dicari'],
  order: 4,
  group: 1,
}, {
  id: 'a_radio_5',
  parent: 'character_evaluation',
  title: 'Hubungan dengan Orang tua',
  fields: 'a_radio_5',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Penuh Ketegangan', 'Jauh','Cukup Dekat', 'Hangat'],
  order: 5,
  group: 1,
}, {
  id: 'a_radio_6',
  parent: 'character_evaluation',
  title: 'Hubungan dengan Suami/Istri (Opsional, jika sudah menikah)',
  fields: 'a_radio_6',
  value: 'Tidak dapat Menilai',
  validation: {
    required: false
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Penuh Ketegangan', 'Cukup Hangat','Hangat', 'Intim'],
  order: 6,
  group: 1,
}, {
  id: 'a_radio_7',
  parent: 'character_evaluation',
  title: 'Ketepatan Waktu',
  fields: 'a_radio_7',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Tidak pernah Tepat Waktu', 'Cukup Tepat','Biasanya Tepat', 'Selalu Tepat'],
  order: 7,
  group: 1,
}, {
  id: 'a_radio_8',
  parent: 'character_evaluation',
  title: 'Tanggung Jawab',
  fields: 'a_radio_8',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Tidak Bertanggung Jawab', 'Kadang Bertanggung Jawab','Cukup Bertanggung Jawab', 'Sangat Bertanggung Jawab'],
  order: 8,
  group: 1,
}, {
  id: 'a_radio_9',
  parent: 'character_evaluation',
  title: 'Kerja sama dalam Tim',
  fields: 'a_radio_9',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Dicari-cari untuk Dijadikan Anggota Tim', 'Bekerja Cukup Baik dengan Orang Lain','Bekerja dengan Baik dalam Tim', 'Suka Bekerja Sendiri'],
  order: 9,
  group: 1,
}, {
  id: 'a_radio_10',
  parent: 'character_evaluation',
  title: 'Kerendahan Hati',
  fields: 'a_radio_10',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Arogan/Sombong', 'Kadang Rendah Hati','Cukup Rendah Hati', 'Sangat Rendah Hati'],
  order: 10,
  group: 1,
}, {
  id: 'a_radio_11',
  parent: 'character_evaluation',
  title: 'Kepedulian/Belas Kasih',
  fields: 'a_radio_11',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Tidak Peduli', 'Kadang Peduli','Cukup Peduli dan Berbelas Kasih', 'Sangat Peduli dan Penuh Belas Kasih'],
  order: 11,
  group: 1,
}, {
  id: 'a_radio_12',
  parent: 'character_evaluation',
  title: 'Kepemimpinan',
  fields: 'a_radio_12',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Tidak Berjiwa Pemimpin', 'Menjanjikan/Berpotensi','Terkadang dapat Memimpin', 'Berkemampuan Memimpin'],
  order: 12,
  group: 1,
}, {
  id: 'a_radio_13',
  parent: 'character_evaluation',
  title: 'Penerimaan terhadap Pimpinan',
  fields: 'a_radio_13',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Melawan Atasannya', 'Kurang Kooperatif','Terkadang Kooperatif', 'Mendukung Atasan'],
  order: 13,
  group: 1,
}, {
  id: 'b_checkbox',
  parent: 'character_evaluation',
  title: 'Berikut ini adalah beberapa kecenderungan yang jika timbul dapat mengurangi efektifitas calon mahasiswa dalam bekerja dan bersaksi. Mohon berikan tanda yang menurut Anda menggambarkan salah satu atau beberapa kecenderungan calon mahasiswa.',
  fields: 'b_checkbox',
  value: [],
  validation: {
    required: false
  },
  option: [
    'Tidak sabar, tidak bertoleransi, argumentatif, mendominasi, merengut, sombong, suka mengkritik.',
    'Mudah merasa malu, mudah tersinggung, kecil hati, depresi, mudah kesal.',
    'Sering kuatir, gelisah, grogi atau tegang.',
    'Membeda-bedakan kelompok tertentu, ras atau kebangsaan.',
    'Berteman secara eksklusif.',
    'Kurang rasa humor atau kemampuan untuk menceritakan lelucon.',
  ],
  type: 'checkbox',
  order: 14,
  group: 2,
}, {
  id: 'b_question_1',
  parent: 'character_evaluation',
  title: 'Jelaskan bentuk dan intensitas kecenderungan yang Anda garis bawahi di atas',
  fields: 'b_question_1',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 15,
  group: 2,
}, {
  id: 'is_free_from_tendencies',
  parent: 'character_evaluation',
  title: 'Calon mahasiswa terlihat bebas dari kecenderungan tersebut di atas',
  fields: 'is_free_from_tendencies',
  value: 'Ya',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Ya', 'Tidak'],
  order: 16,
  group: 2,
}, {
  id: 'c_question_1',
  parent: 'character_evaluation',
  title: 'Apakah calon mahasiswa pernah mengalami suatu penyakit atau ketegangan mental? Jika ya, Bagaimana ia mengatasinya?',
  fields: 'c_question_1',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 17,
  group: 3,
}]
export const character_evaluation_c_mth = [{
  id: 'a_radio_1',
  parent: 'character_evaluation',
  title: 'Penampilan Pribadi dan Kelakuan',
  fields: 'a_radio_1',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Ceroboh', 'Cukup Baik','Rapi', 'Baik'],
  order: 1,
  group: 1,
}, {
  id: 'a_radio_2',
  parent: 'character_evaluation',
  title: 'Sikap',
  fields: 'a_radio_2',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Tidak Percaya Diri', 'Kurang Percaya Diri','Cukup Percaya Diri', 'Percaya Diri'],
  order: 2,
  group: 1,
}, {
  id: 'a_radio_3',
  parent: 'character_evaluation',
  title: 'Memiliki Kestabilan Emosi (bagaimana calon mahasiswa bereaksi dalam keadaan stres)',
  fields: 'a_radio_3',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Tidak Stabil', 'Cukup Seimbang','Seimbang', 'Kadang Tidak Seimbang'],
  order: 3,
  group: 1,
}, {
  id: 'a_radio_4',
  parent: 'character_evaluation',
  title: 'Penerimaan Sosial (bagaimana kepribadian calon mahasiswa berdampak kepada orang lain)',
  fields: 'a_radio_4',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Dapat Ditoleransi', 'Diterima','Disukai', 'Dicari'],
  order: 4,
  group: 1,
}, {
  id: 'a_radio_5',
  parent: 'character_evaluation',
  title: 'Hubungan dengan Orang tua',
  fields: 'a_radio_5',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Penuh Ketegangan', 'Jauh','Cukup Dekat', 'Hangat'],
  order: 5,
  group: 1,
}, {
  id: 'a_radio_6',
  parent: 'character_evaluation',
  title: 'Hubungan dengan Suami/Istri (Opsional, jika sudah menikah)',
  fields: 'a_radio_6',
  value: 'Tidak dapat Menilai',
  validation: {
    required: false
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Penuh Ketegangan', 'Cukup Hangat','Hangat', 'Intim'],
  order: 6,
  group: 1,
}, {
  id: 'a_radio_7',
  parent: 'character_evaluation',
  title: 'Ketepatan Waktu',
  fields: 'a_radio_7',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Tidak pernah Tepat Waktu', 'Cukup Tepat','Biasanya Tepat', 'Selalu Tepat'],
  order: 7,
  group: 1,
}, {
  id: 'a_radio_8',
  parent: 'character_evaluation',
  title: 'Tanggung Jawab',
  fields: 'a_radio_8',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Tidak Bertanggung Jawab', 'Kadang Bertanggung Jawab','Cukup Bertanggung Jawab', 'Sangat Bertanggung Jawab'],
  order: 8,
  group: 1,
}, {
  id: 'a_radio_9',
  parent: 'character_evaluation',
  title: 'Kerja sama dalam Tim',
  fields: 'a_radio_9',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Dicari-cari untuk Dijadikan Anggota Tim', 'Bekerja Cukup Baik dengan Orang Lain','Bekerja dengan Baik dalam Tim', 'Suka Bekerja Sendiri'],
  order: 9,
  group: 1,
}, {
  id: 'a_radio_10',
  parent: 'character_evaluation',
  title: 'Kerendahan Hati',
  fields: 'a_radio_10',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Arogan/Sombong', 'Kadang Rendah Hati','Cukup Rendah Hati', 'Sangat Rendah Hati'],
  order: 10,
  group: 1,
}, {
  id: 'a_radio_11',
  parent: 'character_evaluation',
  title: 'Kepedulian/Belas Kasih',
  fields: 'a_radio_11',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Tidak Peduli', 'Kadang Peduli','Cukup Peduli dan Berbelas Kasih', 'Sangat Peduli dan Penuh Belas Kasih'],
  order: 11,
  group: 1,
}, {
  id: 'a_radio_12',
  parent: 'character_evaluation',
  title: 'Kepemimpinan',
  fields: 'a_radio_12',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Tidak Berjiwa Pemimpin', 'Menjanjikan/Berpotensi','Terkadang dapat Memimpin', 'Berkemampuan Memimpin'],
  order: 12,
  group: 1,
}, {
  id: 'a_radio_13',
  parent: 'character_evaluation',
  title: 'Penerimaan terhadap Pimpinan',
  fields: 'a_radio_13',
  value: 'Tidak dapat Menilai',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak dapat Menilai', 'Melawan Atasannya', 'Kurang Kooperatif','Terkadang Kooperatif', 'Mendukung Atasan'],
  order: 13,
  group: 1,
}, {
  id: 'b_checkbox',
  parent: 'character_evaluation',
  title: 'Berikut ini adalah beberapa kecenderungan yang jika timbul dapat mengurangi efektifitas calon mahasiswa dalam bekerja dan bersaksi. Mohon berikan tanda yang menurut Anda menggambarkan salah satu atau beberapa kecenderungan calon mahasiswa.',
  fields: 'b_checkbox',
  value: [],
  validation: {
    required: false
  },
  option: [
    'Tidak sabar, tidak bertoleransi, argumentatif, mendominasi, merengut, sombong, suka mengkritik.',
    'Mudah merasa malu, mudah tersinggung, kecil hati, depresi, mudah kesal.',
    'Sering kuatir, gelisah, grogi atau tegang.',
    'Membeda-bedakan kelompok tertentu, ras atau kebangsaan.',
    'Berteman secara eksklusif.',
    'Kurang rasa humor atau kemampuan untuk menceritakan lelucon.',
  ],
  type: 'checkbox',
  order: 14,
  group: 2,
}, {
  id: 'b_question_1',
  parent: 'character_evaluation',
  title: 'Jelaskan bentuk dan intensitas kecenderungan yang Anda garis bawahi di atas',
  fields: 'b_question_1',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 15,
  group: 2,
}, {
  id: 'is_free_from_tendencies',
  parent: 'character_evaluation',
  title: 'Calon mahasiswa terlihat bebas dari kecenderungan tersebut di atas',
  fields: 'is_free_from_tendencies',
  value: 'Ya',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Ya', 'Tidak'],
  order: 16,
  group: 2,
}]
export const recommendation = [{
  id: 'recommendation_radio',
  parent: 'recommendation',
  title: 'Seberapa antusiaskah Anda merekomendasikan calon mahasiswa untuk mendaftarkan diri di STT Amanat Agung?',
  fields: 'recommendation_radio',
  value: 'Tidak Merekomendasikan',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Tidak Merekomendasikan', 'Kurang Merekomendasikan', 'Merekomendasikan','Sangat Merekomendasikan'],
  order: 1,
  group: 1,
}, {
  id: 'recommendation_reason',
  parent: 'recommendation',
  title: 'Alasan:',
  fields: 'recommendation_reason',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 2,
  group: 1,
}]
export const recommendation_sert = [{
  id: 'recommendation_radio',
  parent: 'recommendation',
  title: 'Seberapa antusiaskah Anda merekomendasikan calon mahasiswa untuk mendaftarkan diri di STT Amanat Agung?',
  fields: 'recommendation_radio',
  value: 'Sangat Antusias',
  validation: {
    required: true
  },
  type: 'select',
  option: ['Sangat Antusias', 'Kuat', 'Cukup Kuat','Keberatan'],
  order: 1,
  group: 1,
}, {
  id: 'recommendation_reason',
  parent: 'recommendation',
  title: 'Alasan:',
  fields: 'recommendation_reason',
  value: '',
  validation: {
    required: true
  },
  type: 'textArea',
  order: 2,
  group: 1,
}]
export const recommendation_other_person = [{
  id: 'name_recommendation_other_person',
  parent: 'recommendation_other_person',
  title: 'Nama & Hubungan dengan Anda Ke-1',
  subfields: 'name',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'text',
  order: 1,
  group: 1,
  num: 1,
  attr: 'person1',
}, {
  id: 'address_recommendation_other_person',
  parent: 'recommendation_other_person',
  title: 'Alamat Ke-1',
  subfields: 'address',
  fields: 'list',
  value: '',
  validation: {
    required: false
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'text',
  order: 2,
  group: 1,
  num: 1,
  attr: 'person1',
}, {
  id: 'phone_recommendation_other_person',
  parent: 'recommendation_other_person',
  title: 'Nomor HP Ke-1',
  subfields: 'phone',
  fields: 'list',
  value: '',
  validation: {
    required: false,
    phoneNumber: true,
  },
  isMultiData: true,
  isDisabledRemove: true,
  type: 'number',
  order: 3,
  group: 1,
  num: 1,
  attr: 'person1',
}]
export const filler_data = [{
  id: 'filler_name',
  parent: 'filler_data',
  title: 'Nama',
  fields: 'filler_name',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 1 
}, {
  id: 'filler_position',
  parent: 'filler_data',
  title: 'Posisi/ Jabatan',
  fields: 'filler_position',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 2
}, {
  id: 'filler_church',
  parent: 'filler_data',
  title: 'Gereja/ Organisasi',
  fields: 'filler_church',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 3
}, {
  id: 'filler_address',
  parent: 'filler_data',
  title: 'Alamat',
  fields: 'filler_address',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 4
}, {
  id: 'filler_phone',
  parent: 'filler_data',
  title: 'Telepon',
  fields: 'filler_phone',
  value: '',
  validation: {
    required: true
  },
  type: 'number',
  order: 5
}, {
  id: 'filler_email',
  parent: 'filler_data',
  title: 'E-mail',
  fields: 'filler_email',
  value: '',
  validation: {
    required: true,
    email: true
  },
  type: 'text',
  order: 6
}]
export const agreement_data = [{
  id: 'sign_date',
  parent: 'agreement_data',
  title: 'Tanggal Menyatakan Persetujuan',
  fields: 'sign_date',
  value: '',
  validation: {
    required: true
  },
  type: 'date',
  order: 2
}, {
  id: 'sign_pict_url',
  parent: 'agreement_data',
  title: 'Tanda Tangan Perekomendasi (file PNG)',
  fields: 'sign_pict_url',
  value: [],
  validation: {
    required: true,
    accept: '.png, .jpg, .jpeg',
    isImg: true
  },
  type: 'file',
  order: 3
}]